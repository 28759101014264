import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, concat, map, mergeMap } from 'rxjs/operators';
import { deleteTileSuccess, newTileSuccess, sortTilesSuccess } from '../actions/tileActions';
import { appError, closeModal, formError } from '../actions/uiActions';
import * as API from '../services/firebase';
import { getUID } from '../selectors/authSelectors';
import { getSections, getTileById, getBoardTiles } from '../selectors/boardSelectors';
import { enhanceError } from '../lib/common';
import { addTileToSection, removeTileFromSection, moveTilesToSection } from '../lib/sections';
import * as Models from '../services/models';

export const newTileEpic = (action$, state$, { firebase, getTimestamp }) =>
  action$.pipe(
    ofType('NEW_TILE'),
    mergeMap(({ payload: { tileData: tileFormData, boardId, sectionId } }) => {
      // TODO: add loader for tile creation
      const state = state$.value;
      const uid = getUID(state);
      const boardSections = getSections(state, { boardId });
      const { name, type, content, author } = tileFormData;
      const hasFiles = content instanceof File;

      // Check for mandatory values
      if ([name, type, content].some(x => !x)) {
        return of(
          formError({
            form: 'newTile',
            error: {
              code: 'tile/missing-value',
              error: `Some of mandatory values for tile is missing`,
            },
          }),
        );
      }

      // Create tile model
      // Quick/hackis fix to differenciet between different tiles (they have different data),
      // TODO: need to refactor
      const factoryType = type === 'ImageTile' ? 'imageTile' : 'tile';

      const tileData = Models[factoryType].create({
        ...tileFormData,
        ...(sectionId && { section: sectionId }),
        content: (hasFiles && 'loading') || content,
        author: author || uid,
        modifiedAt: getTimestamp(),
      });

      // Update section
      const sections = addTileToSection(boardSections || {}, tileData.section, tileData.id);

      let tileFiles$ = of(true);

      if (hasFiles) {
        const path = `tiles/${boardId}/${tileData.id}/${content}`;
        tileFiles$ = from(API.uploadToStorage(firebase, content, path));
      }

      const newTile$ = from(API.newTile(firebase, tileData, boardId, sections));

      return newTile$.pipe(
        map(tile => newTileSuccess(tile)),
        concat(tileFiles$.pipe(map(() => closeModal()))),
        catchError(err => of(formError({ form: 'newTile', error: err }))),
      );
    }),
  );

export const deleteTileEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('TILE_DELETE'),
    mergeMap(({ payload: { tileId, boardId } }) => {
      const state = state$.value;
      const boardSections = getSections(state, { boardId });
      const tile = getTileById(state, { boardId, tileId });

      const sections = removeTileFromSection(boardSections || {}, [[tile.section, tileId]]);

      return from(API.deleteTile(firebase, boardId, tileId, sections)).pipe(
        map(() => deleteTileSuccess()),
        catchError(err => of(appError({ error: enhanceError(err, { context: 'tile_delete' }) }))),
      );
    }),
  );

export const sortTilesEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('TILE_SORT'),
    mergeMap(({ payload: { boardId, droppedId, targetId, newSection, direction } }) => {
      const boardSections = getSections(state$.value, { boardId });
      // When moving tile to empty section we get undefined as position
      const position = boardSections[newSection]
        ? boardSections[newSection].indexOf(targetId) + direction
        : undefined;
      const tiles = getBoardTiles(boardId)(state$.value);
      const tile = tiles[droppedId];
      // We have to take up to date section from store, as it is not updated in dragged object
      const oldSection = tile.section;
      const updatedSections = moveTilesToSection(boardSections, [
        { oldSection, newSection, tile: droppedId, position },
      ]);

      return from(
        API.newTile(firebase, { ...tile, section: newSection }, boardId, updatedSections),
      ).pipe(
        map(() => sortTilesSuccess()),
        catchError(err => of(appError({ error: enhanceError(err, { context: 'tile_sort' }) }))),
      );
    }),
  );
