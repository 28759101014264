import identity from 'ramda/es/identity';

// Action creator type for actions with payload

// Action creator type for actions without payload

// Action creator helper function
export default function actionCreator(type, payloadCreator = identity) {
  const actionCreatorFactory = (p, meta) => {
    const action = { type };

    if (meta !== undefined) {
      action.meta = meta;
    }

    if (payloadCreator !== null) {
      action.payload = payloadCreator(p);
    }

    return action;
  };

  return actionCreatorFactory;
}
