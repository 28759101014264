/**
 * This has to be kept in sync with `qjub-extension/src/js/shared/services/permissions.ts`
 */
import { RoleDefinitions, Roles } from '../constants/roles';

type RolePermissionValidator = (params: object) => boolean;
interface ConditionalAction {
  name: string;
  when: RolePermissionValidator;
}

type RolesPermissions = {
  [role in Roles]?: {
    can: Record<string, boolean | RolePermissionValidator>;
    extend?: Roles[];
  };
};

/*
 * Permissions class
 *
 * This class handles user permission in the app based on role definitions supplied.
 */
class Permissions {
  roles: RolesPermissions = {};

  // Maybe I should rename it to something more explanatory
  init = (rolesDefinitions: RoleDefinitions) => {
    if (typeof rolesDefinitions !== 'object') {
      throw new Error(
        `Permissions.init() requires Object as a parameter, not a ${typeof rolesDefinitions}`,
      );
    }

    if (Array.isArray(rolesDefinitions)) {
      throw new Error(`Permissions.init() requires Object as a parameter, not an array`);
    }

    const roles: RolesPermissions = {};

    (Object.keys(rolesDefinitions) as Roles[]).forEach(role => {
      const roleDef = rolesDefinitions[role];

      roles[role] = {
        can: {},
      };

      if (roleDef.extend) {
        // @ts-ignore - we do not care about readonly flag here
        roles[role]!.extend = roleDef.extend;
      }

      if (roleDef.can && Array.isArray(roleDef.can)) {
        roleDef.can.forEach(action => {
          if (typeof action === 'string') {
            roles[role]!.can[action] = true;
            // @ts-ignore
          } else if (typeof action.name === 'string' && typeof action.when === 'function') {
            // @ts-ignore
            roles[role]!.can[action.name] = action.when;
          }
        });
      }
    });

    this.roles = roles;
  };

  /* TODO: maybe we could swap role and action a bit so we can avoid inverseBoardPermissionMapping:
  Permissions.can(
    inverseBoardPermissionMapping[boardPermission],
    'tile:create'
  );
  */

  can = (role: Roles, action: string, params?: any): boolean => {
    const $role = this.roles[role];

    if (!$role) {
      console.error(`Role ${role} does not exist in provided role definitions`);
      return false;
    }

    const actionToValidate = $role.can[action];

    if (actionToValidate) {
      if (typeof actionToValidate === 'boolean') {
        return true;
      }

      if (actionToValidate(params)) {
        return true;
      }
    }

    if ($role.extend && $role.extend.length > 0) {
      return $role.extend.some(extRole => this.can(extRole, action, params));
    }

    return false;
  };
}

export default new Permissions();
