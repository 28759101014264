// TODO: download prop-types as standalone pkg
import React, { Component, Children } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

class ContextProvider extends Component {
  static propTypes = {
    firebase: PropTypes.object.isRequired,
  };
  // you must specify what you’re adding to the context
  static childContextTypes = {
    firebase: PropTypes.object.isRequired,
  };
  getChildContext() {
    const { firebase } = this.props;
    return { firebase };
  }
  render() {
    // `Children.only` enables us not to add a <div /> for nothing
    return Children.only(this.props.children);
  }
}
export default ContextProvider;
