import { empty } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

export const redirectAfterBoardDeletionEpic = (action$, _, { history }) =>
  action$.pipe(
    ofType('BOARD_DELETE_SUCCESS'),
    tap(() => history.push('/')),
    switchMap(({ payload }) => empty())
  );

export const logoutRedirectEpic = (action$, _, { history }) =>
  action$.pipe(
    ofType('LOGOUT_SUCCESS'),
    tap(() => history.push('/auth')),
    switchMap(({ payload }) => empty())
  );
