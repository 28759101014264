import insert from 'ramda/es/insert';
import update from 'ramda/es/update';
import { ofType } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  deleteBoardSuccess,
  deleteSectionSuccess,
  fetchUserBoardsSuccess,
  newBoardSuccess,
  newSectionSuccess,
  removeSectionSuccess,
  renameSectionSuccess,
} from '../actions/boardActions';
import { appError, closeModal, formError } from '../actions/uiActions';
import { DEFAULT_BOARD_SECTION } from '../constants/config';
import { encodeStringToId, enhanceError } from '../lib/common';
import {
  deleteSection as deleteSectionFromBoard,
  removeSection as removeSectionFromBoard,
  splitSection,
} from '../lib/sections';
import { isUndefined } from '../lib/validations';
import { getUID } from '../selectors/authSelectors';
import { getBoardData, getBoardTiles, getSections } from '../selectors/boardSelectors';
import * as API from '../services/firebase';
import * as Models from '../services/models';

/**
 * Fetch user's boards
 */
export const fetchUserBoardsEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_FETCH_USER_BOARDS'),
    switchMap(() => {
      const uid = getUID(state$.value);

      return from(API.fetchUserBoards(firebase, uid)).pipe(
        map(boards => fetchUserBoardsSuccess({ boards })),
        catchError(err =>
          of(
            appError({
              error: enhanceError(err, { context: 'BOARD_FETCH_USER_BOARDS' }),
            }),
          ),
        ),
      );
    }),
  );

/**
 * Handles new board event and API call to create new board
 */
export const newBoardEpic = (action$, state$, { firebase, getTimestamp, generateId }) =>
  action$.pipe(
    ofType('BOARD_NEW'),
    switchMap(({ payload }) => {
      const { board } = payload;
      const { name, visibility } = board;
      const uid = getUID(state$.value);

      if (isUndefined(name, visibility)) {
        return of(
          formError({
            form: 'newBoard',
            error: { code: 'board-no-empty-field' },
          }),
        );
      }

      let boardData;
      // We are editing the board if boardId exists
      if (board.boardId) {
        // If we want to edit board we have to use only board meta because otherwise we can
        // overwrite already existing sections. This is due to the fact that when editing board on
        // user dashboard we do not have entire board data. But since when editing board we only
        // need very little information this is ok.
        // Another approach would be to fetch entire board detail and overwrite it. Question is
        // whether this is necessary.
        boardData = Models.boardMeta.create({
          ...board,
          modifiedAt: getTimestamp(),
        });
      } else {
        boardData = Models.board.create({
          ...board,
          owner: uid,
        });
      }

      const board$ = from(API.newBoard(firebase, boardData, uid));

      return board$.pipe(
        switchMap(() => of(newBoardSuccess(), closeModal())),
        catchError(err => of(formError({ form: 'newBoard', error: err }))),
      );
    }),
  );

/**
 * Handles delete board event and API call to delete existing board
 */
export const deleteBoardEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_DELETE'),
    switchMap(({ payload }) => {
      const { boardId } = payload;
      const uid = getUID(state$.value);
      const board$ = from(API.deleteBoard(firebase, boardId, uid));

      return board$.pipe(
        map(() => deleteBoardSuccess()),
        catchError(err => of(appError({ error: enhanceError(err, { context: 'board_delete' }) }))),
      );
    }),
  );

/**
 * Create new section on the board
 */
// TODO: if one update of tile fail (for example from 5 updated) epic fail but won't recover from failure - some tiles are moved and some not
// TODO: update to work as edit section as well (maybe event delete/remove section?)
export const newSectionEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_NEW_SECTION'),
    switchMap(({ payload: { boardId, index, sectionId } }) => {
      // TODO: tahle akci by měla spustit loader
      const state = state$.value;
      const uid = getUID(state);
      const activeBoard = getBoardData(boardId)(state);
      const boardSections = getSections(state, { boardId });
      const tiles = getBoardTiles(boardId)(state);

      const isEmptySection = index === undefined;

      // We return Promise resolve instead of empty Observable because forkJoin is expecting
      // some value and it won't trigger without it
      let updatedTiles$ = of(Promise.resolve());
      let boardUpdate$ = of(Promise.resolve());

      // board update preparation
      // TODO: section id should be independed on section-name, in the url we can consider construting html id from name and id if necessary
      // TODO: Consider creating function which generates this, also this class could handle generating other data objects (board, tile...) default and updated values - easier data migration in the future
      const newSection = {
        id: encodeStringToId('New section'),
        name: 'New section',
      };

      // Update tiles positions in sections
      const sections = splitSection(boardSections, sectionId, index, () => newSection.id);

      // get new order of sections
      const newSectionIndex = sectionId
        ? activeBoard.sections.findIndex(s => s.id === sectionId) + 1
        : 0;
      const sectionOrder = insert(newSectionIndex, newSection, activeBoard.sections);

      const boardData = Object.assign({}, activeBoard, {
        sections: sectionOrder,
      });
      const movedTiles = sections[newSection.id];

      // TODO: the if may be unnecessary in the future
      if (isEmptySection) {
        // eslint-disable-next-line
        console.log('New empty section created');
      } else {
        // Edit tiles only if there are actualy some tiles to edit
        if (movedTiles.length) {
          updatedTiles$ = forkJoin(
            ...movedTiles.map(tile =>
              from(
                API.newTile(
                  firebase,
                  { ...tiles[tile], section: newSection.id },
                  boardId,
                  sections,
                ),
              ),
            ),
          );
        }

        boardUpdate$ = from(API.newBoard(firebase, boardData, uid));
      }

      return forkJoin(updatedTiles$, boardUpdate$).pipe(
        map(() => newSectionSuccess()),
        catchError(err => of(appError({ error: enhanceError(err, { context: 'new_section' }) }))),
      );
    }),
  );

export const removeSectionEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_REMOVE_SECTION'),
    switchMap(({ payload: { sectionId, boardId } }) => {
      // TODO: tahle akci by měla spustit loader
      const state = state$.value;
      const uid = getUID(state);
      const activeBoard = getBoardData(boardId)(state);
      const boardSections = getSections(state, { boardId });
      const tiles = getBoardTiles(boardId)(state);

      const movedTiles = Object.values(tiles || {}).filter(tile => tile.section === sectionId);

      // We return Promise resolve instead of empty Observable because forkJoin is expecting
      // some value and it won't trigger without it
      let updatedTiles$ = of(Promise.resolve());
      let boardUpdate$ = of(Promise.resolve());

      const updatedSections = activeBoard.sections.filter(s => s.id !== sectionId);
      const boardData = Object.assign({}, activeBoard, {
        sections: updatedSections,
      });

      const newBoardSections = removeSectionFromBoard(boardSections, sectionId);

      // Edit tiles only if there are actualy some tiles to edit
      if (movedTiles.length) {
        updatedTiles$ = forkJoin(
          ...movedTiles.map(tile =>
            from(
              API.newTile(
                firebase,
                { ...tile, section: DEFAULT_BOARD_SECTION },
                boardId,
                newBoardSections,
              ),
            ),
          ),
        );
      }

      boardUpdate$ = from(API.newBoard(firebase, boardData, uid));

      return forkJoin(updatedTiles$, boardUpdate$).pipe(
        map(() => removeSectionSuccess()),
        catchError(err =>
          of(
            appError({
              error: enhanceError(err, { context: 'remove_section' }),
            }),
          ),
        ),
      );
    }),
  );

/**
 * Create new section on the board
 */
// TODO: if one update of tile fail (for example from 5 updated) epic fail byt won't recover from failure - some tiles are moved and some not
export const deleteSectionEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_DELETE_SECTION'),
    switchMap(({ payload: { boardId, sectionId } }) => {
      // TODO: tahle akci by měla spustit loader
      const state = state$.value;
      const uid = getUID(state);
      const activeBoard = getBoardData(boardId)(state);
      const boardSections = getSections(state, { boardId });

      const deletedTiles = boardSections[sectionId];
      // We return Promise resolve instead of empty Observable because forkJoin is expecting
      // some value and it won't trigger without it
      let deletedTiles$ = of(Promise.resolve());
      let boardUpdate$ = of(Promise.resolve());

      const sections = activeBoard.sections.filter(s => s.id !== sectionId);
      const boardData = Object.assign({}, activeBoard, { sections });

      const newBoardSections = deleteSectionFromBoard(boardSections, sectionId);

      if (deletedTiles) {
        deletedTiles$ = forkJoin(
          ...deletedTiles.map(tile =>
            from(API.deleteTile(firebase, boardId, tile, newBoardSections)),
          ),
        );
      }

      boardUpdate$ = from(API.newBoard(firebase, boardData, uid));

      return forkJoin(deletedTiles$, boardUpdate$).pipe(
        map(() => deleteSectionSuccess()),
        catchError(err =>
          of(
            appError({
              error: enhanceError(err, { context: 'delete_section' }),
            }),
          ),
        ),
      );
    }),
  );

export const renameSectionEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType('BOARD_RENAME_SECTION'),
    switchMap(({ payload: { boardId, sectionId, name } }) => {
      const state = state$.value;
      const uid = getUID(state$.value);
      const activeBoard = getBoardData(boardId)(state);

      // TODO: Consider creating function which generates this, also this class could handle generating other data objects (board, tile...) default and updated values - easier data migration in the future
      const renameSection = {
        id: sectionId,
        name: name,
      };

      const sectionIndex = activeBoard.sections.findIndex(s => s.id === sectionId);

      const sections = update(sectionIndex, renameSection, activeBoard.sections);
      const boardData = Object.assign({}, activeBoard, { sections });

      return from(API.newBoard(firebase, boardData, uid)).pipe(
        map(() => renameSectionSuccess()),
        catchError(err =>
          of(
            appError({
              error: enhanceError(err, { context: 'rename_section' }),
            }),
          ),
        ),
      );
    }),
  );
