export function isUndefined(...values) {
  if (values.length === 0) {
    throw new Error(
      'No values for undefined check provided. You have to call isUndefined function with at least one value',
    );
  }

  for (const value of values) {
    if (typeof value === 'undefined') {
      return true;
    }
  }

  return false;
}

// Function check if obj param is object structure (not a array, function or any other structure
// evaluated by typeof as Object). Eg only data like this should pass: {a: 5}
export function isObject(obj) {
  return (
    obj === Object(obj) &&
    obj.constructor === Object &&
    !Array.isArray(obj) &&
    obj.apply === undefined
  );
}

// For yup validations
export function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}
