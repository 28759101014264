import uuid from 'uuid';
import Hashids from 'hashids';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { HASH_SALT } from '../constants/config';
import { inverseBoardPermissionMapping } from '../constants';

const hashids = new Hashids(HASH_SALT, 8);

// TODO: tests
// TODO: more options - returns object with unit, number (size as number) and string which commbines the size and the unit
export function getFileSize(fileSize, decimals = 2) {
  const UNITS = ['KB', 'MB', 'GB', 'TB'];
  let sOutput = `${fileSize} bytes`;

  // code for multiples approximation
  for (let nMultiple = 0, nApprox = fileSize / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
    sOutput = `${nApprox.toFixed(decimals)} ${UNITS[nMultiple]}`;
  }

  return sOutput;
}

// TODO: test and flow
/**
 * This function takes an error object and enhance it to custom error object consumed by app.
 * Right now it only adds unique error id
 * error [Object]: error object to enhance
 */

export function enhanceError(error, meta = {}) {
  return Object.assign({}, error, { id: uuid.v4(), ...meta });
}

/**
 * @param {Omit<AppMessage, 'id'>} [message] - app message
 */
export function createAppMessage(message) {
  return Object.assign({}, message, { id: uuid.v4() });
}

// NOTE: maybe do something more robust?
export function encodeStringToId(string) {
  const getRandom = () => Math.floor(Math.random() * (256 - 0));

  const prefix = hashids.encode([getRandom(), getRandom(), getRandom(), getRandom()]);

  return prefix;
}

// not pure function, but importing constatn to something which will be used very often seems
// as tedious work
// TODO: consider moving this to permission service
function codeToRoleFactory(rolesMapping) {
  return code => (typeof code === 'number' ? rolesMapping[code] : 'none');
}

export const codeToRole = codeToRoleFactory(inverseBoardPermissionMapping);

// Not pure function, but it helps as an abstraction and we can pass it as
// dependency elsewhere
export function getTimestamp() {
  return firebase.database.ServerValue.TIMESTAMP;
}

// Not pure function
// return id generated by firebase, may be replaced in the future for example by uuid package
// or something completely different. Again works as better abstraction as we can easily inject it
// dependency and it can be easily mocked in tests
export function generateId(path) {
  return firebase
    .database()
    .ref()
    .child(path)
    .push().key;
}

export function round(value, decimals = 2) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}
