import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import configureEpics from '../epics';
import { rootReducer } from '../reducers';

export default function configureStore(options) {
  const { platformDeps, platformEpics, platformReducers } = options;

  const reducers = rootReducer(platformReducers);

  // Middlewares setup
  const epicMiddleware = createEpicMiddleware({
    dependencies: { ...platformDeps },
  });

  const middlewares = [];

  if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
      collapsed: true,
    });
    middlewares.push(logger);
  }

  middlewares.push(epicMiddleware);

  const enhancers = [applyMiddleware(...middlewares)];

  // Redux devtools
  if (window && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  // Create store
  const store = createStore(reducers, {}, compose(...enhancers));

  // Run root epic
  const rootEpic = configureEpics(platformEpics);
  epicMiddleware.run(rootEpic);

  return store;
}
