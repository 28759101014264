/**
 * This has to be kept in sync with `qjub-extension/src/js/shared/services/models.js`
 */
import { createModelFactory } from '../lib/createModelFactory';
import {
  DEFAULT_USER_TYPE,
  DEFAULT_TILE_TYPE,
  DEFAULT_BOARD_VISIBILITY,
  DEFAULT_BOARD_SECTION,
} from '../constants/config';
import { getTimestamp, generateId } from '../lib/common';
import { getTileSize } from '../lib/tiles';

// ========================
//     BOARD DEFINITION
// ========================

export const board = createModelFactory({
  boardId: () => generateId('boards'),
  name: '',
  owner: '',
  createdAt: getTimestamp,
  modifiedAt: getTimestamp,
  visibility: DEFAULT_BOARD_VISIBILITY,
  sections: [{ id: DEFAULT_BOARD_SECTION }],
});

export const boardMeta = createModelFactory({
  boardId: () => generateId('boards'),
  name: '',
  owner: '',
  createdAt: getTimestamp,
  modifiedAt: getTimestamp,
  visibility: DEFAULT_BOARD_VISIBILITY,
});

// ========================
//     TILE DEFINITION
// ========================

export const tile = createModelFactory({
  id: () => generateId('tiles'),
  name: '',
  author: '',
  type: DEFAULT_TILE_TYPE,
  content: '',
  size: getTileSize,
  createdAt: getTimestamp,
  modifiedAt: getTimestamp,
  section: DEFAULT_BOARD_SECTION,
});

export const imageTile = createModelFactory({
  id: () => generateId('tiles'),
  name: '',
  author: '',
  type: 'ImageTile',
  content: '',
  size: getTileSize,
  source: '',
  createdAt: getTimestamp,
  modifiedAt: getTimestamp,
  section: DEFAULT_BOARD_SECTION,
});

// ========================
//     USER DEFINITION
// ========================

export const user = createModelFactory({
  uid: '',
  email: '',
  type: DEFAULT_USER_TYPE,
});
