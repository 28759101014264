export const getConsentCookie = () =>
  document.cookie.split(';').filter(function(item) {
    return item.indexOf('consent_given=') >= 0;
  });

export const hasConsent = () => {
  const consentCookie = getConsentCookie();
  return consentCookie.length > 0 && consentCookie[0] === 'consent_given=1';
};

export const hasCookie = (): boolean => {
  const consentCookie = getConsentCookie();
  return consentCookie.length > 0 && consentCookie[0].startsWith('consent_given=');
};
