export type SearchState = {
  query: string;
};

const initialState: SearchState = { query: '' };

function searchReducer(state: SearchState = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY': {
      const {
        payload: { query },
      } = action;

      return { query };
    }

    default:
      return state;
  }
}

export default searchReducer;
