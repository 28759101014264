import { css } from 'styled-components/macro';
import { rem, tint, shade } from 'polished';

/**
 * Function return correct font-size and line-height for given font-size values in px
 */
export function getFontSize(size: number) {
  return css`
    font-size: ${rem(size)};
    line-height: ${({ theme }) =>
      (Math.ceil(size / theme.sizes.lineHeight) * theme.sizes.lineHeight) / size};
  `;
}

export function maxWidth(padded = true) {
  return css`
    max-width: ${({ theme }) =>
      padded ? css`calc(var(--max-width) + ${theme.sizes.gapTwo})` : `var(--max-width)`};
    margin: 0 auto;
  `;
}

export function colorTint(color: string, value: 1 | 2 | 3) {
  return tint(value * 0.1, color);
}

export function colorShade(color: string, value: 1 | 2 | 3) {
  return shade(value * 0.1, color);
}

export function resetButtonStyles() {
  return css`
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  `;
}
