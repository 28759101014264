import { combineReducers } from 'redux';
import authReducer from './authReducer';
import boardReducer from './boardReducer';
import permissionsReducer from './permissions';
import searchReducer from './searchReducer';
import tileReducer, { TileState } from './tileReducer';
import uiReducer from './uiReducer';

export const rootReducer = (platformReducers) =>
  combineReducers({
    auth: authReducer,
    boards: boardReducer,
    tiles: tileReducer,
    ui: uiReducer,
    permissions: permissionsReducer,
    search: searchReducer,
    ...platformReducers,
  });

export type ReduxState = {
  tiles: TileState;
};
