const initialState = {
  user: undefined,
  searchToken: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_USER_SUCCESS': {
      return Object.assign(state, { user: action.payload.user });
    }

    case 'LOGIN_SUCCESS': {
      return Object.assign(state, { user: action.payload });
    }

    case 'GET_SEARCH_TOKEN_SUCCESS': {
      return Object.assign(state, { searchToken: action.payload.key });
    }

    case 'LOGOUT_SUCCESS': {
      return Object.assign(state, { user: null, searchToken: null });
    }

    default:
      return state;
  }
}

export default authReducer;
