import { padding, rem } from 'polished';
import React, { Children, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';
import { maxWidth } from '../../lib/styles';

export const Main = styled.main<{ noHeader?: boolean }>`
  ${maxWidth()};
  min-height: 100vh;
  ${({ theme, noHeader }) =>
    noHeader
      ? css`
          ${padding(rem(theme.sizes.lineHeight * 2), rem(theme.sizes.lineHeight * 0.75))}
        `
      : css`
          ${padding(
            rem(theme.sizes.lineHeight * 4),
            rem(theme.sizes.lineHeight * 0.75),
            rem(theme.sizes.lineHeight * 2),
          )}
        `}

  @media ${({ theme }) => theme.bp.tablet} {
    ${({ theme, noHeader }) =>
      noHeader
        ? css`
            ${padding(rem(theme.sizes.lineHeight * 2), theme.sizes.gapOne)}
          `
        : css`
            ${padding(
              rem(theme.sizes.lineHeight * 4),
              theme.sizes.gapOne,
              rem(theme.sizes.lineHeight * 2),
            )}
          `}
  }

  @media ${({ theme }) => theme.bp.lScreen} {
    ${({ theme, noHeader }) =>
      noHeader
        ? css`
            ${padding(rem(theme.sizes.lineHeight * 2), theme.sizes.gapTwo)}
          `
        : css`
            ${padding(
              rem(theme.sizes.lineHeight * 4),
              theme.sizes.gapTwo,
              rem(theme.sizes.lineHeight * 2),
            )}
          `}
  }

  @media ${({ theme }) => theme.bp.xxlScreen} {
    ${({ theme, noHeader }) =>
      noHeader
        ? css`
            ${padding(rem(theme.sizes.lineHeight * 2), 0)}
          `
        : css`
            ${padding(rem(theme.sizes.lineHeight * 4), 0, rem(theme.sizes.lineHeight * 2))}
          `}
  }
`;

// TODO: refactor to grid
export const GridList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: calc(var(--horizontal--grid-spacing) * -1);
    margin-right: calc(var(--horizontal--grid-spacing) * -1);

    & > ${GridItem} {
      flex-grow: 1;
      flex-basis: 100%;
      max-width: 100%;
    }

    @media ${theme.bp.tablet} {
      & > ${GridItem} {
        max-width: calc(50% - (var(--horizontal--grid-spacing) * 2));
      }
    }

    @media ${theme.bp.screen} {
      & > ${GridItem} {
        max-width: calc((100% / 3) - (var(--horizontal--grid-spacing) * 2));
      }
    }

    @media ${theme.bp.xxlScreen} {
      & > ${GridItem} {
        max-width: calc((100% / 4) - (var(--horizontal--grid-spacing) * 2));
      }
    }
  `}
`;

export const GridItem = styled.li`
  ${({ theme }) => css`
    margin-bottom: var(--vertical-grid-spacing);
    margin-left: var(--horizontal--grid-spacing);
    margin-right: var(--horizontal--grid-spacing);
  `}
`;

type LayoutProps = {
  gap?: 'half' | 'one' | 'two' | 'three' | 'four';
  alignment?: 'start' | 'end' | 'center';
};

export const StackWrapper = styled.div<LayoutProps>(
  ({ theme, gap = 'one', alignment = 'start' }) =>
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: ${alignment === 'center'
        ? 'center'
        : alignment === 'end'
        ? 'flex-end'
        : 'flex-start'};

      > div {
        margin-bottom: ${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]};
        width: 100%;
        display: flex;
        justify-content: ${alignment === 'center'
          ? 'center'
          : alignment === 'end'
          ? 'flex-end'
          : 'flex-start'};
      }

      > div:last-child {
        margin-bottom: 0;
      }

      > div:empty {
        display: none;
      }
    `,
);

export function Stack({ gap, alignment, children }: PropsWithChildren<LayoutProps>) {
  return (
    <StackWrapper gap={gap} alignment={alignment}>
      {Children.map(children, (child) => (child ? <div>{child}</div> : null))}
    </StackWrapper>
  );
}

/**
 * [ 1 ] There is rouding issue in Firefox which caused the row to break to next line because of
 *       few px decimal points. This should prevent it and be small enough to not affect
 *       anything else.
 */
export const RowWrapper = styled.div<LayoutProps & { wrap?: boolean }>(
  ({ theme, gap = 'one', alignment = 'start', wrap = true }) => css`
    width: calc(
      100% + ${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]} + 0.5px
    ); /* [ 1 ] */
    display: flex;
    flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
    justify-content: ${alignment === 'center'
      ? 'center'
      : alignment === 'end'
      ? 'flex-end'
      : 'flex-start'};
    margin-top: calc(${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]} / -2);
    margin-left: calc(${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]} / -2);
    margin-right: calc(${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]} / -2);

    > div {
      display: flex;
      align-items: center;
      margin: calc(${theme.sizes[`gapFixed${gap[0].toUpperCase()}${gap.slice(1)}`]} / 2);
      margin-bottom: 0;
    }
  `,
);

export function Row({
  gap,
  alignment,
  children,
  wrap,
}: PropsWithChildren<LayoutProps & { wrap?: boolean }>) {
  return (
    <RowWrapper gap={gap} alignment={alignment} wrap={wrap}>
      {Children.map(children, (child) => child && <div>{child}</div>)}
    </RowWrapper>
  );
}

export const Container = styled.div<{ size?: 'narrow' | 'medium' | 'full' }>(
  ({ theme, size = 'full' }) => css`
    margin: 0 auto;

    @media ${theme.bp.tablet} {
      ${size === 'full' &&
      css`
        width: 100%;
      `};

      ${size === 'medium' &&
      css`
        width: 75vw;
      `};

      ${size === 'narrow' &&
      css`
        min-width: calc(${theme.sizes.gapFixedOne} * 20);
        width: calc(${theme.sizes.gapOne} * 18);
        /* max-width: calc(${theme.sizes.gapFixedOne} * 20); */
      `};
    }

    @media ${theme.bp.screen} {
      ${size === 'full' &&
      css`
        width: 100%;
      `};

      ${size === 'medium' &&
      css`
        width: 50vw;
      `};

      ${size === 'narrow' &&
      css`
        width: calc(${theme.sizes.gapOne} * 12);
        max-width: calc(${theme.sizes.gapFixedOne} * 20);
      `};
    }
  `,
);

export const FullWidth = styled.div`
  width: 100%;
`;
