import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import createHistory from 'history/createBrowserHistory';
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { fetchUserSuccess, getSearchToken } from './actions/authActions';
import GlobalStyles from './components/atoms/GlobalStyles';
import { OverlayLoader } from './components/atoms/Loader';
import ContextProvider from './components/ContextProvider';
import CookieBanner from './components/CookieBanner';
import Head from './components/Head';
import RoleDefinitions from './constants/roles';
import theme from './constants/theme';
import * as webRoutingEpics from './epics/webEpics/routingEpics';
import { firebaseConfig } from './firebase.config';
import { generateId, getTimestamp } from './lib/common';
import { hasConsent } from './lib/cookie';
import configureStore from './lib/store';
import Auth from './services/Auth';
import * as Api from './services/firebase';
import Permissions from './services/permissions';

/* DEBUG OPTIONS */
firebase.initializeApp(firebaseConfig);

// Dummy object before we initialize analytics and performance in order to avoid errors when calling
// them in the code without initialization
let analytics = {
  setUserId: (...args: any[]) => {},
  logEvent: (...args: any[]) => {},
  setAnalyticsCollectionEnabled: (...args: any[]) => {},
};
let perf = {};

if (process.env.NODE_ENV === 'production') {
  import('@sentry/browser').then((Sentry) => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `qjub-app@${process.env.REACT_APP_VERSION}`,
      environment: process.env.REACT_APP_ENV,
    });
  });

  if (hasConsent()) {
    import('firebase/analytics').then(() => {
      analytics = firebase.analytics();
      analytics.setAnalyticsCollectionEnabled(true);
      const user = firebase.auth().currentUser;
      if (user) {
        analytics.setUserId(user.uid);
      }
    });
    import('firebase/performance').then(() => {
      perf = firebase.performance();
    });
  }
} else {
  // firebase.database.enableLogging(function(message) {
  //   console.log('[FIREBASE]', message);
  // });
}

// Initialize auth helpers
Auth.init(firebase);

const history = createHistory();

const store = configureStore({
  platformDeps: {
    firebase,
    getTimestamp,
    generateId,
    history,
  },
  platformEpics: { ...webRoutingEpics },
});

store.dispatch({ type: 'app/INIT' });

Permissions.init(RoleDefinitions);

const Signup = React.lazy(() => import('./screens/Signup'));
const Welcome = React.lazy(() => import('./screens/Welcome'));
const ForgotPassword = React.lazy(() => import('./screens/ForgotPassword'));
const App = React.lazy(() => import('./screens/App'));

class AppWrapper extends React.Component<{}, {}, {}> {
  authListener: firebase.Unsubscribe | null = null;

  showFocusRing = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      document.body.classList.add('show-focus-ring');
    }
  };

  componentDidMount() {
    this.authListener = Api.authChange(firebase, (user: firebase.User) => {
      store.dispatch(fetchUserSuccess({ user }));

      if (user) {
        store.dispatch(getSearchToken());
        analytics.setUserId(user.uid);
      }
    });

    document.body.classList.remove('show-focus-ring');

    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', this.showFocusRing);
  }

  componentWillUnmount() {
    if (this.authListener) {
      this.authListener();
    }

    document.body.removeEventListener('keyup', this.showFocusRing);
  }

  render() {
    return (
      <Provider store={store}>
        <ContextProvider firebase={firebase}>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyles />
              <Head />
              <Router history={history}>
                <Suspense fallback={<OverlayLoader fullscreen />}>
                  <Switch>
                    <Route path="/signup" component={Signup} />
                    <Route path="/auth" component={Welcome} />
                    <Route path="/forgot-password" component={ForgotPassword} exact />
                    <Route path="/" component={App} />
                  </Switch>
                  <Route component={CookieBanner} />
                </Suspense>
              </Router>
            </>
          </ThemeProvider>
        </ContextProvider>
      </Provider>
    );
  }
}

render(<AppWrapper />, document.getElementById('root'));
