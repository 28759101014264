//

import actionCreator from '../lib/actionCreator';

export const newBoard = actionCreator('BOARD_NEW');

export const newBoardSuccess = actionCreator('BOARD_NEW_SUCCESS');

export const fetchUserBoards = actionCreator('BOARD_FETCH_USER_BOARDS');

export const fetchUserBoardsSuccess = actionCreator('BOARD_FETCH_USER_BOARDS_SUCCESS');

export const fetchUserBoardsError = actionCreator('BOARD_FETCH_USER_BOARDS_ERROR');

export const fetchBoardDetail = actionCreator('BOARD_FETCH_DETAIL');

export const fetchBoardDetailSuccess = actionCreator('BOARD_FETCH_DETAIL_SUCCESS');

export const fetchBoardDetailError = actionCreator('BOARD_FETCH_DETAIL_ERROR');

export const fetchBoardSectionsSuccess = actionCreator('BOARD_FETCH_SECTIONS_SUCCESS');

export const newSection = actionCreator('BOARD_NEW_SECTION');

export const newSectionSuccess = actionCreator('BOARD_NEW_SECTION_SUCCESS');

export const renameSection = actionCreator('BOARD_RENAME_SECTION');

export const renameSectionSuccess = actionCreator('BOARD_RENAME_SECTION_SUCCESS');

export const removeSection = actionCreator('BOARD_REMOVE_SECTION');

export const removeSectionSuccess = actionCreator('BOARD_REMOVE_SECTION_SUCCESS');

export const deleteSection = actionCreator('BOARD_DELETE_SECTION');

export const deleteSectionSuccess = actionCreator('BOARD_DELETE_SECTION_SUCCESS');

export const deleteBoard = actionCreator('BOARD_DELETE');

export const deleteBoardSuccess = actionCreator('BOARD_DELETE_SUCCESS');
