import { math, rem } from 'polished';

const GAP_SIZE = '3.125vw';

const colors = {
  brand: '#22B6BD',
  // brand: '#36B1BF',
  highlight: '#2AEFDB',
  success: '#19e6c0',
  error: '#FF053B',
  warning: '#FFDC1C',
  black: '#141412',
  gray6: '#6b6f70',
  gray5: '#8A9399',
  gray4: '#A4B0B3',
  gray3: '#C8CCCF',
  gray2: '#E0E3E4',
  // gray0: '#EFEFEF',
  gray1: '#F5F8FA',
  white: '#FFFFFF',
} as const;

const sizes = {
  // Gap sizes
  gapQuater: math(`${GAP_SIZE} / 4`),
  gapHalf: math(`${GAP_SIZE} / 2`),
  gapOne: GAP_SIZE,
  gapTwo: math(`${GAP_SIZE} * 2`),
  gapFixedHalf: rem(22.5 / 2),
  gapFixedOne: rem(22.5),
  gapFixedOneHalf: rem(22.5 * 1.5),
  gapFixedTwo: rem(22.5 * 2),
  gapFixedThree: rem(22.5 * 3),
  gapFixedFour: rem(22.5 * 4),

  // font sizes
  // number in pixel, will be converted to correct values and units in `font()` helper function
  fontSmall: 12,
  fontSmaller: 14,
  fontBase: 16,
  fontOne: 22.5,
  fontTwo: 30,
  fontThree: 42.188,
  fontFour: 56.25,

  // Helper values
  MODULAR_SCALE: 1.875,
  //line-height  in px will be converted to correct unitless number in font() helper
  lineHeight: 22.5,

  maxWidth: '90rem',
} as const;

const bp = {
  mobile: 'screen and (min-width: 30rem)', // 480px
  tablet: 'screen and (min-width: 45rem)', // 720px
  screen: 'screen and (min-width: 65rem)', // 1040px
  lScreen: 'screen and (min-width: 80rem)', // 1280px
  xlScreen: 'screen and (min-width: 100rem)', // 1600px
  xxlScreen: 'screen and (min-width: 120rem)', // 1920px
  xxxlScreen: 'screen and (min-width: 144rem)', // 2304px
} as const;

const z = {
  below: -1,
  base: 0,
  above: 1,
  cover: 5,
  header: 75,
  overlay: 99,
  modal: 100,
} as const;

const shadows = {
  light: `0 6px 12px -6px ${colors.gray2}`,
  normal: `0 5px 15px -7px ${colors.gray3}`,
  heavy: `0 5.9px 7.5px -9px rgba(0, 0, 0, 0.058), 0 10.3px 13.9px -9px rgba(0, 0, 0, 0.073),
  0 12.9px 19.5px -9px rgba(0, 0, 0, 0.081), 0 14.4px 25.3px -9px rgba(0, 0, 0, 0.086),
  0 20.1px 34.2px -9px rgba(0, 0, 0, 0.093), 0 40px 64px -9px rgba(0, 0, 0, 0.12)`,
} as const;

const theme = {
  colors,
  sizes,
  bp,
  z,
  shadows,
} as const;

export default theme;

export type Theme = typeof theme;
