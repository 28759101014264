import { createSelector } from 'reselect';
import path from 'ramda/es/path';

export const getBoards = appState => appState.boards;
export const getBoardsList = appState => appState.boards.list;

export const getUserBoards = createSelector(
  getBoards,
  boards => (boards.list ? Object.values(boards.list) : null),
);

export const getActiveBoard = createSelector(
  getBoards,
  boards => boards.activeBoard,
);

export const getBoardData = boardId =>
  createSelector(
    getBoardsList,
    boards => boards[boardId],
  );

// TODO: tiles selectors - consider moving them to different file
export const getTiles = appState => appState.tiles;

export const getBoardTiles = boardId =>
  createSelector(
    getTiles,
    tiles => tiles.list[boardId],
  );

export const getTileById = createSelector(
  getTiles,
  (_, { boardId, tileId }) => ({ boardId, tileId }),
  (tiles, { boardId, tileId }) => path(['list', boardId, tileId], tiles),
);

export const getSections = (appState, props) => appState.boards.sections[props.boardId];
