import styled, { css } from 'styled-components/macro';
import { colorTint } from '../../lib/styles';

export const Link = styled.a`
  ${({ theme }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    color: ${theme.colors.black};
    transition: color 125ms ease-out;
    text-decoration: none;
    will-change: transform;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: currentColor;
    }

    &:visited {
      color: ${colorTint(theme.colors.black, 3)};
    }

    &:hover,
    &:focus {
      color: ${colorTint(theme.colors.black, 2)};
    }

    &:active {
      color: ${theme.colors.gray3};
    }
  `}
`;
