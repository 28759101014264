import { isObject } from './validations';

export function createModelFactory(defaultObj) {
  return {
    create: function(data = {}) {
      // We accept only objects but no array or functions
      if (!isObject(data) && data !== undefined) {
        throw Error('You have to pass object to model factory create method');
      }

      const defautlKeys = Object.keys(defaultObj);
      const newObject = {};

      defautlKeys.forEach(key => {
        const val = data[key];

        if (val !== undefined) {
          newObject[key] = val;
        } else {
          const defaultVal = defaultObj[key];

          if (typeof defaultVal === 'function') {
            newObject[key] = defaultVal();
          } else {
            newObject[key] = defaultVal;
          }
        }
      });

      return newObject;
    },

    getDefaultValue: function(value) {
      if (defaultObj.hasOwnProperty(value)) {
        return defaultObj[value];
      }

      throw Error(`This model does not contain property ${value}`);
    },
  };
}
