import evolve from 'ramda/es/evolve';
import compose from 'ramda/es/compose';
import filter from 'ramda/es/filter';
import isNil from 'ramda/es/isNil';
import mergeDeepLeft from 'ramda/es/mergeDeepLeft';

export const initialState = {
  boards: {},
};

function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PERMISSIONS_SUCCESS': {
      return evolve({
        boards: compose(
          filter(prop => !isNil(prop)),
          mergeDeepLeft(action.payload.boards),
        ),
      })(state);
    }

    case 'LOGOUT_SUCCESS': {
      return initialState;
    }

    default:
      return state;
  }
}

export default permissionReducer;
