import { rem } from 'polished';
import * as React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { round } from '../../lib/common';
import { Stack } from '../common/Layout';
import { getFontSize } from '../../lib/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div(
  ({ theme }) => css`
    width: ${rem(round(theme.sizes.lineHeight * 1.5, 0))};
    height: ${rem(round(theme.sizes.lineHeight * 1.5, 0))};
    border-radius: 50%;
    border: 2px solid ${theme.colors.black};
    border-bottom-color: transparent;
    animation: ${rotate} 0.75s linear infinite;
  `,
);

const Text = styled.span(
  ({ theme }) => css`
    ${getFontSize(theme.sizes.fontBase)};
    color: ${theme.colors.gray5};
    font-variant: all-small-caps;
  `,
);

/**
 * [ 1 ] - top and bottom padding of Main component
 */
const LoaderWrapper = styled.div<{ fullscreen?: boolean }>(
  ({ theme, fullscreen }) => css`
    width: 100%;
    height: ${fullscreen
      ? '100vh'
      : `calc(100vh - ${rem(theme.sizes.lineHeight * 6)})`}; /* [ 1 ] */
    padding-top: ${fullscreen ? rem(theme.sizes.lineHeight * 2) : 0};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

const InlineWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverlayLoader = ({ fullscreen = false }: { fullscreen?: boolean }) => (
  <LoaderWrapper className="loader" fullscreen={fullscreen}>
    <Stack gap="half" alignment="center">
      <Spinner />
      <Text>Loading&hellip;</Text>
    </Stack>
  </LoaderWrapper>
);

export const InlineLoader = ({ label }: { label?: string }) => (
  <InlineWrapper>
    <Stack gap="half" alignment="center">
      <Spinner />
      <Text>{label || 'Loading\u2026'}</Text>
    </Stack>
  </InlineWrapper>
);
