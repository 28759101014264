import * as firebaseNs from 'firebase/app';
import 'firebase/auth';

// TODO: consider merging this with API file, firebase methods are all over the place now. Or consider just passing these method to place where they should be.
export type AuthTypes = 'signIn' | 'register' | 'reAuth' | 'link';

class Auth {
  firebase: typeof firebaseNs | null = null;

  providerId = {
    google: firebaseNs.auth.GoogleAuthProvider.PROVIDER_ID,
    email: firebaseNs.auth.EmailAuthProvider.PROVIDER_ID,
  };

  init = (firebaseRef: typeof firebaseNs) => {
    this.firebase = firebaseRef;
  };

  authException = (message: string) => ({
    message,
  });

  checkIsInit = () => {
    if (!this.firebase) {
      throw this.authException(
        'No firebase reference added, please initialize class with init() method first',
      );
    }
  };

  getSocialAuthProvider = (providerId: string) => {
    this.checkIsInit();

    // if (providerId === this.providerId.facebook) {
    //   return new this.firebase.auth.FacebookAuthProvider();
    // }

    if (providerId === this.providerId.google) {
      return new this.firebase!.auth.GoogleAuthProvider();
    }

    return null;
  };

  // facebookSignin = () => {
  //   this.checkIsInit();

  //   const provider = new this.firebase.auth.FacebookAuthProvider();

  //   provider.setCustomParameters({
  //     display: 'popup',
  //   });

  //   return this.firebase.auth().signInWithPopup(provider);
  //   // .then((result) => {
  //   //   const token = result.credential.accessToken
  //   //   const user = result.user
  //   // })
  // };

  googleSignin = () => {
    this.checkIsInit();

    const provider = new this.firebase!.auth.GoogleAuthProvider();

    provider.addScope('profile');
    provider.addScope('email');

    return this.firebase!.auth()
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Google Access Token.
        // const token = result.credential.accessToken
        // The signed-in user info.
        // const user = result.user
        // TODO: onSuccess handlerd
        console.warn(result);
        return result;
      });
  };

  reauthenticateWithPopup = (provider: firebaseNs.auth.AuthProvider) => {
    this.checkIsInit();

    return this.firebase!.auth().currentUser!.reauthenticateWithPopup(provider);
  };

  link = (provider: firebaseNs.auth.AuthProvider) => {
    this.checkIsInit();

    if (!this.firebase!.auth().currentUser) {
      throw new Error('User not authenticated. `link` method can be used only by logged in users.');
    }

    return this.firebase!.auth().currentUser!.linkWithPopup(provider);
  };

  // linkFacebook = () => {
  //   this.checkIsInit();

  //   const provider = new this.firebase.auth.FacebookAuthProvider();

  //   return this.link(provider);
  // };

  linkGoogle = () => {
    this.checkIsInit();

    const provider = new this.firebase!.auth.GoogleAuthProvider();

    return this.link(provider);
  };

  unlink = (providerId: string) => {
    this.checkIsInit();

    const user = this.firebase!.auth().currentUser;

    if (!user) {
      throw new Error('User not authenticated. `link` method can be used only by logged in users.');
    }

    if (user.providerData.length === 1 && providerId === user.providerData[0]!.providerId) {
      throw this.authException('Can not remove only auth provider');
    }

    return user.unlink(providerId);
    // .catch(error => Promise.reject(error))
  };

  unlinkGoogle = () => this.unlink(this.providerId.google);

  // unlinkFacebook = () => this.unlink(this.providerId.facebook);

  // unlinkPhone = () => this.unlink(this.providerId.phone);

  unlinkEmail = () => this.unlink(this.providerId.email);

  deleteAccount = () => {
    this.checkIsInit();

    if (!this.firebase!.auth().currentUser) {
      throw new Error('User not authenticated. `link` method can be used only by logged in users.');
    }

    return this.firebase!.auth().currentUser!.delete();
  };
}

export default new Auth();
