import { ofType } from 'redux-observable';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { setLoading } from '../actions/uiActions';

// TODO: login and signup error should be perhaps replaced with form_error or form_error will be called on them
export const authStopLoadingEpic = action$ =>
  action$.pipe(
    ofType('APP_ERROR', 'FORM_ERROR', 'FETCH_USER_SUCCESS'),
    // We use switchMap and of in order to dispatch two actions at once
    switchMap(() =>
      of(
        setLoading({ stateKey: 'USER', value: false }),
        setLoading({ stateKey: 'BOARD', value: false }),
      ),
    ),
  );

export const finishBoardLoadingEpic = action$ =>
  combineLatest(
    action$.pipe(ofType('FETCH_BOARD_TILES_SUCCESS')),
    action$.pipe(ofType('BOARD_FETCH_USER_BOARDS_SUCCESS')),
  ).pipe(map(() => setLoading({ stateKey: 'BOARD', value: false })));
