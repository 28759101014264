import { math, rem } from 'polished';
import { createGlobalStyle } from 'styled-components/macro';
import interBoldWoff from '../../assets/fonts/Inter-Bold.woff';
import interBoldWoff2 from '../../assets/fonts/Inter-Bold.woff2';
import interMediumWoff from '../../assets/fonts/Inter-Medium.woff';
import interMediumWoff2 from '../../assets/fonts/Inter-Medium.woff2';
import interRegularWoff from '../../assets/fonts/Inter-Regular.woff';
import interRegularWoff2 from '../../assets/fonts/Inter-Regular.woff2';
import interSemiboldWoff from '../../assets/fonts/Inter-SemiBold.woff';
import interSemiboldWoff2 from '../../assets/fonts/Inter-SemiBold.woff2';
import interVarBaseWoff2 from '../../assets/fonts/Inter.var-base.woff2';
import interVarLatinWoff2 from '../../assets/fonts/Inter.var-latin.woff2';
import { getFontSize } from '../../lib/styles';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${interRegularWoff}) format('woff'),
         url(${interRegularWoff2}) format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+0-17F,U+2000-206F,U+20AC,U+27F6;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${interMediumWoff}) format('woff'),
         url(${interMediumWoff2}) format('woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    unicode-range: U+0-17F,U+2000-206F,U+20AC,U+27F6;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${interSemiboldWoff}) format('woff'),
         url(${interSemiboldWoff2}) format('woff2');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    unicode-range: U+0-17F,U+2000-206F,U+20AC,U+27F6;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${interBoldWoff}) format('woff'),
         url(${interBoldWoff2}) format('woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    unicode-range: U+0-17F,U+2000-206F,U+20AC,U+27F6;
  }

  /* Variable fonts */
  @font-face {
    font-family: 'Inter-Variable';
    src: url(${interVarBaseWoff2}) format("woff2");
    font-weight: 1 999;
    font-display: swap;
    unicode-range: U+0-7F;
  }

  @font-face {
    font-family: 'Inter-Variable';
    src: url(${interVarLatinWoff2}) format("woff2");
    font-weight: 1 999;
    font-display: swap;
    unicode-range: U+80-17F,U+2000-206F,U+20AC,U+27F6,U+2192;
  }

  :root {
    box-sizing: border-box;
    /* Define font-stacks */
    --fonts-fallback: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    --fonts: 'Inter',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

    /* Sizes */
    --max-width: 90rem;
    --horizontal-grid-spacing: 0;
    --vertical-grid-spacing: ${({ theme }) => theme.sizes.gapFixedOne};

    /* Define app colors */
    --highlight: ${({ theme }) => theme.colors.highlight};
    --success: ${({ theme }) => theme.colors.success};
    --error: ${({ theme }) => theme.colors.error};
    --warning: ${({ theme }) => theme.colors.warning};
    --black: ${({ theme }) => theme.colors.black};
    --gray-5: ${({ theme }) => theme.colors.gray5};
    --gray-4: ${({ theme }) => theme.colors.gray4};
    --gray-3: ${({ theme }) => theme.colors.gray3};
    --gray-2: ${({ theme }) => theme.colors.gray2};
    --gray-1: ${({ theme }) => theme.colors.gray1};
    --white: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.bp.tablet} {
      --horizontal--grid-spacing: ${({ theme }) => theme.sizes.gapFixedHalf};
    }

    @media ${({ theme }) => theme.bp.xxlScreen} {
      --max-width: 100rem;
      --horizontal--grid-spacing: ${({ theme }) => rem(theme.sizes.lineHeight * 0.75)};
      --vertical-grid-spacing: ${({ theme }) => rem(theme.sizes.lineHeight * 1.5)};

    }
  }

  @supports (font-variation-settings: normal) {
    :root {
      --fonts: 'Inter-Variable',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background-color: ${({ theme }) => theme.colors.gray1};
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;

    font-family: var(--fonts-fallback);
    font-feature-settings: 'calt', 'liga', 'case', 'ss01', 'zero', 'dlig';
    ${({ theme }) => getFontSize(theme.sizes.fontBase)};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};

    /* font rendering optimalizations */
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
  }

  input,
  textarea,
  button,
  body {
    font-family: var(--fonts-fallback);
    font-feature-settings: 'calt', 'liga', 'case', 'ss01', 'zero', 'dlig';
  }

  .fonts-loaded input,
  .fonts-loaded textarea,
  .fonts-loaded button,
  .fonts-loaded body {
    font-family: var(--fonts);
  }

  ::selection {
    background-color: ${({ theme }) => theme.colors.highlight};
  }

  /**
  * Taken from https://necolas.github.io/normalize.css/
  * [ 1 ] - Correct the inheritance and scaling of font size in all browsers.
  * [ 2 ] - Correct the odd 'em' font sizing in all browsers.
  */
  pre {
    font-family: monospace, monospace; /* [ 1 ] */
    font-size: 1em; /* [ 2 ] */
  }

  code {
    font-family: monospace, monospace; /* [ 1 ] */
  }

  p {
    margin-top: 0;
  }

  strong {
    font-weight: 700;
  }

  hr {
    border-top: 0;
    border-color: ${({ theme }) => theme.colors.gray2};
    margin: ${({ theme }) => math(`${theme.sizes.gapFixedOne} * 2`)} 0 ;
  }

  img {
    display: block;
  }

  body:not(.show-focus-ring) {
    input:focus,
    textarea:focus,
    select:focus,
    button:focus,
    a:focus,
    [tab-index="-1"]:focus {
      outline: 0;
    }
  }

  body.show-focus-ring {
    input:focus,
    textarea:focus,
    select:focus,
    button:focus,
    a:focus,
    [tab-index="-1"]:focus {
      outline: 1px dotted ${({ theme }) => theme.colors.gray4};
      outline-offset: 2px;
    }
  }
`;

export default GlobalStyles;
