import assocPath from 'ramda/es/assocPath';
import defaultTo from 'ramda/es/defaultTo';
import dissocPath from 'ramda/es/dissocPath';

const initialState = {
  list: null,
};

function boardReducer(state = initialState, action) {
  switch (action.type) {
    case 'BOARD_FETCH_USER_BOARDS_SUCCESS':
      return assocPath(['list'], defaultTo({}, action.payload.boards), state);

    case 'BOARD_FETCH_DETAIL_SUCCESS': {
      const { boardId, board } = action.payload;

      return board
        ? assocPath(['list', boardId], board, state)
        : dissocPath(['list', boardId], state);
    }

    case 'BOARD_FETCH_SECTIONS_SUCCESS': {
      const { boardId, tilesOrder, sectionId } = action.payload;
      return assocPath(['sections', boardId, sectionId], tilesOrder, state);
    }

    case 'LOGOUT_SUCCESS':
      return (state = initialState);

    default:
      return state;
  }
}

export default boardReducer;
