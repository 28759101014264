import * as firebase from 'firebase/app';
import React, { useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import Button from './common/Button';
import { Link } from './atoms/Link';
import { Small } from './atoms/Text';
import { hasConsent, hasCookie } from '../lib/cookie';
import { rem } from 'polished';

const Banner = styled.div(
  ({ theme }) => css`
    display: none;
    position: fixed;
    bottom: ${theme.sizes.gapFixedOne};
    right: ${theme.sizes.gapFixedHalf};
    left: ${theme.sizes.gapFixedHalf};
    z-index: 99999;
    padding: ${theme.sizes.gapFixedOne};
    font-size: ${rem(theme.sizes.fontSmall)};
    background-color: ${theme.colors.white};
    box-shadow: 0 3px 2.4px rgba(0, 0, 0, 0.043), 0 5.3px 5.3px rgba(0, 0, 0, 0.049),
      0 7.5px 9.4px rgba(0, 0, 0, 0.058), 0 10.1px 16.8px rgba(0, 0, 0, 0.08),
      0 14.1px 34px rgba(0, 0, 0, 0.122), 0 25px 100px rgba(0, 0, 0, 0.2);

    &.show {
      display: block !important;
    }

    & > div {
      display: flex;
      justify-content: flex-end;
    }

    & p {
      margin-bottom: ${theme.sizes.gapFixedHalf};
    }

    & button {
      margin-left: ${theme.sizes.gapFixedHalf};
    }

    @media (--tablet) {
      /* width: calc(50vw); */
      left: ${theme.sizes.gapFixedOne};
      right: ${theme.sizes.gapFixedOne};
      padding: ${theme.sizes.gapFixedOne} ${theme.sizes.gapFixedTwo};
    }
  `,
);

function initCookies() {
  // set cookie confirming that user agree with tracking
  document.cookie =
    'consent_given=1;domain=qjub.app;path=/;max-age=' + (60 * 60 * 24 * 365).toString();
  // Allow GA
  window['ga-disable-UA-151897193-1'] = false;
  window['ga-disable-UA-151897193-2'] = false;
  window['ga-disable-G-DEPK9XFPN8'] = false;

  // Init Firebase services
  import('firebase/analytics').then(() => {
    const analytics = firebase.analytics();
    const user = firebase.auth().currentUser;
    if (user) {
      analytics.setUserId(user.uid);
    }
  });
  import('firebase/performance').then(() => {
    firebase.performance();
  });
}

function CookieBanner() {
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const agreeCallback = useCallback(() => {
    initCookies();
    bannerRef.current!.classList.remove('show');
  }, []);

  const disagreeCallback = useCallback(() => {
    document.cookie = 'consent_given=0;path=/;max-age=' + (60 * 60 * 24 * 365).toString();
    bannerRef.current!.classList.remove('show');
  }, []);

  useEffect(() => {
    if (!bannerRef.current) return;

    if (hasConsent()) {
      initCookies();
    } else if (!hasCookie()) {
      bannerRef.current!.classList.add('show');
    }
  }, []);

  if (hasCookie()) {
    return null;
  }

  if (process.env.NODE_ENV === 'development') {
    return null;
  }

  return (
    <Banner ref={bannerRef}>
      <p>
        <Small>
          This site uses cookies. Some of them are optional and help me to improve Qjub and make it
          a better product. You can read more about used cookies and what data Qjub collects in{' '}
          <Link href="https://qjub.app/privacy">Privacy Policy</Link>.
        </Small>
      </p>
      <p>
        <Small>
          Please confirm whether you agree or disagree with optional cookies. They won't be used
          without your consent.
        </Small>
      </p>
      <div>
        <Button
          type="button"
          onClick={disagreeCallback}
          color="primary"
          variant="inverse"
          small
          fixedWidth={false}
          className="button button--inline button--primary button--inverse button--small"
        >
          Disagree
        </Button>
        <Button
          type="button"
          onClick={agreeCallback}
          color="primary"
          small
          fixedWidth={false}
          className="button button--inline button--primary button--small"
        >
          Agree
        </Button>
      </div>
    </Banner>
  );
}

export default CookieBanner;
