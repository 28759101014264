import append from 'ramda/es/append';
import assoc from 'ramda/es/assoc';
import compose from 'ramda/es/compose';
import concat from 'ramda/es/concat';
import defaultTo from 'ramda/es/defaultTo';
import evolve from 'ramda/es/evolve';
import has from 'ramda/es/has';
import identity from 'ramda/es/identity';
import ifElse from 'ramda/es/ifElse';
import indexOf from 'ramda/es/indexOf';
import insert from 'ramda/es/insert';
import mergeRight from 'ramda/es/mergeRight';
import omit from 'ramda/es/omit';
import splitAt from 'ramda/es/splitAt';
import without from 'ramda/es/without';
import __ from 'ramda/es/__';
import { DEFAULT_BOARD_SECTION } from '../constants/config';
import { encodeStringToId } from './common';
import { isUndefined } from './validations';

// Add tile to sections object to correct section and position
export function addTileToSection(sections, sectionId, tileId) {
  const section = sections[sectionId];

  if (section) {
    return evolve(
      {
        [sectionId]: section.includes(tileId) ? identity : append(tileId),
      },
      sections,
    );
  }

  return assoc(sectionId, [tileId], sections);
}

// Helper function to remove tiles from sections object - ussualy when deleting tile
export function removeTileFromSection(sections, tiles) {
  if (!Array.isArray(tiles)) {
    throw new Error('Only pass array of tuples [sectionId, tileId] as 2nd argument');
  }

  let newSections = identity(sections);

  for (const tile of tiles) {
    if (!Array.isArray(tile)) {
      throw new Error(
        'Array of tiles to remove must contain only array of shape [sectionId, tileId]',
      );
    }

    const [sectionId, tileId] = tile;

    newSections = evolve({
      [sectionId]: without([tileId]),
    })(newSections);
  }

  return newSections;
}

// Move tiles across different sections
export function moveTilesToSection(sections, tiles) {
  if (!Array.isArray(tiles)) {
    throw new Error('Second parameter must be array');
  }

  let updateSections = identity(sections);

  for (const movedTile of tiles) {
    const { tile, oldSection, newSection, position } = movedTile;

    if (isUndefined(tile, oldSection, newSection)) {
      throw new Error('Missing some of mandatory parameters for correct tile movement to section');
    }

    // we do tile movement only for existing sections and tiles
    if (updateSections[oldSection] && updateSections[oldSection].includes(tile)) {
      const isInSectionMovement = indexOf(tile, defaultTo([], updateSections[newSection])) > -1;
      const delta =
        isInSectionMovement &&
        position &&
        indexOf(tile, defaultTo([], updateSections[newSection])) - position < 0
          ? -1
          : 0;

      updateSections = compose(
        evolve({
          [newSection]: position != undefined ? insert(position + delta, tile) : append(tile),
        }),
        evolve({
          [oldSection]: without([tile]),
        }),
        ifElse(has(newSection), identity, assoc(newSection, [])),
      )(updateSections);
    }
  }

  return updateSections;
}

export function splitSection(sections, sectionId, index, idGenerator = encodeStringToId) {
  if (typeof index !== 'number') {
    throw new Error('tileId must be string or undefined');
  }

  const newSectionId = idGenerator();

  if (typeof newSectionId !== 'string') {
    throw new Error('idGenerator function must return string.');
  }

  if (sections[sectionId]) {
    const split = splitAt(index, sections[sectionId]);

    return compose(
      assoc(sectionId, split[0]),
      assoc(newSectionId, split[1]),
    )(sections);
  }

  return sections;
}

export function deleteSection(sections, sectionId) {
  if (sections[sectionId]) {
    return omit([sectionId], sections);
  }

  return sections;
}

export function removeSection(sections, sectionId) {
  if (sections[sectionId]) {
    const tiles = sections[sectionId];

    return compose(
      omit([sectionId]),
      evolve({
        [DEFAULT_BOARD_SECTION]: compose(
          concat(__, tiles),
          defaultTo([]),
        ),
      }),
      // This will add empty default section to the sections object if it is emty
      // since empty sections are not in the object (Firebase removes empty nodes)
      mergeRight({ [DEFAULT_BOARD_SECTION]: [] }),
    )(sections);
  }

  return sections;
}

// TODO: implement
// export function moveSection() {}
