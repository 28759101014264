//

import { ofType } from 'redux-observable';
import { from, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getSearchTokenSuccess,
  logoutUserSuccess,
  resetPasswordSuccess,
} from '../actions/authActions';
import { appError, formError } from '../actions/uiActions';
import * as Api from '../services/firebase';

export const resetPasswordEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType('RESET_PASSWORD'),
    switchMap(({ payload: { email } }) =>
      from(Api.resetPassword(firebase, email)).pipe(
        map(() => resetPasswordSuccess()),
        catchError((error) => of(formError({ form: 'resetPassword', error }))),
      ),
    ),
  );

export const logoutEpic = (action$, _, { history, firebase }) =>
  action$.pipe(
    ofType('LOGOUT'),
    switchMap(() =>
      from(Api.logout(firebase)).pipe(
        map(() => logoutUserSuccess()),
        catchError((error) => of(appError({ error }))),
      ),
    ),
  );

export const getSearchTokenEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType('GET_SEARCH_TOKEN'),
    switchMap(() => {
      const user = firebase.auth().currentUser;

      if (user) {
        return from(user.getIdToken());
      }

      return throwError("Can't get id token when no user is logged in. Login first.");
    }),
    switchMap((idToken) =>
      from(
        fetch(
          `https://us-central1-${
            firebase.app().options.projectId
          }.cloudfunctions.net/getSearchToken`,
          {
            headers: {
              Authorization: 'Bearer ' + idToken,
            },
          },
        ),
      ).pipe(
        switchMap((res) => {
          if (res.ok) {
            return res.json();
          }

          return throwError(`Request for search token failed with error: ${res.statusText}`);
        }),
        map(getSearchTokenSuccess),
        catchError((error) => of(appError({ error }))),
      ),
    ),
  );
