import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  noRobots?: boolean;
  children?: any;
};

export default ({
  title = 'Qjub \u2013 Collect, organize and share knowledge with ease',
  description = `Store information from various places and services in one place. Organize it to boards so it's easy to read and search for by you and your team.`,
  image,
  noRobots = false,
  children,
}: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image ? image : 'https://my.qjub.app/share_image.png'} />
      <meta name="og:image" content={image ? image : 'https://my.qjub.app/share_image.png'} />
      {noRobots && <meta name="robots" content="noindex, nofollow" />}
      {children}
    </Helmet>
  );
};
