import { fluidRange, hideVisually, rem } from 'polished';
import styled, { css } from 'styled-components/macro';
import { getFontSize } from '../../lib/styles';

export const H1 = styled.h1<{ noMargin?: boolean }>(
  ({ theme, noMargin = false }) => css`
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: `${theme.sizes.fontThree}px`,
        toSize: `${theme.sizes.fontFour}px`,
      },
      '320px',
      '1040px',
    )}
    line-height: 1.12;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: ${noMargin ? 0 : rem(theme.sizes.lineHeight)};

    @media ${theme.bp.tablet} {
      letter-spacing: 0;
      line-height: 1.2;
    }
  `,
);

export const H2 = styled.h2<{ noMargin?: boolean }>`
  ${({ theme, noMargin = false }) => css`
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: `${theme.sizes.fontTwo}px`,
        toSize: `${theme.sizes.fontThree}px`,
      },
      '320px',
      '1040px',
    )}
    line-height: ${theme.sizes.lineHeight / theme.sizes.fontBase};
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: ${noMargin ? 0 : rem(theme.sizes.lineHeight * 2)};
  `}
`;

export const H3 = styled.h3<{ noMargin?: boolean }>(
  ({ theme, noMargin = false }) => css`
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: `${theme.sizes.fontOne}px`,
        toSize: `${theme.sizes.fontTwo}px`,
      },
      '320px',
      '1040px',
    )}
    color: ${theme.colors.black};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: ${noMargin ? 0 : rem(theme.sizes.lineHeight)};
  `,
);

export const H4 = styled.h4<{ noMargin?: boolean }>`
  ${({ theme }) => getFontSize(theme.sizes.fontBase)};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: ${({ theme, noMargin = false }) => (noMargin ? 0 : rem(theme.sizes.lineHeight))};
`;

export const H5 = styled.h5<{ noMargin?: boolean }>`
  ${({ theme }) => getFontSize(theme.sizes.fontBase)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  margin-bottom: ${({ theme, noMargin = false }) =>
    noMargin ? 0 : rem(theme.sizes.lineHeight / 2)};
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

export const H6 = styled.h6<{ noMargin?: boolean }>`
  ${({ theme }) => getFontSize(theme.sizes.fontBase)};
  font-variant: all-small-caps;
  font-weight: 600;
  letter-spacing: 2px;
  color: var(--gray-4);
  margin-top: 0;
  margin-bottom: ${({ theme, noMargin = false }) => (noMargin ? 0 : rem(theme.sizes.lineHeight))};
`;

export const Subtitle = styled.span(
  ({ theme }) => css`
    ${getFontSize(theme.sizes.fontSmaller)};
    text-transform: uppercase;
    color: inherit;
    letter-spacing: 1px;
    font-weight: 350;
  `,
);

export const Text = styled.p`
  margin-top: 0;
  margin-bottom: ${({ theme }) => rem(theme.sizes.lineHeight)};
`;

export const Small = styled.small`
  ${({ theme }) => getFontSize(theme.sizes.fontSmall)};
  color: ${({ theme }) => theme.colors.black};
`;

export const Ul = styled.ul``;

export const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.black};
`;

export const SROnly = styled.span`
  ${hideVisually()};
`;
