//

import actionCreator from '../lib/actionCreator';

export const newTile = actionCreator('NEW_TILE');

export const newTileSuccess = actionCreator('NEW_TILE_SUCCESS');

export const fetchBoardTilesSuccess = actionCreator('FETCH_BOARD_TILES_SUCCESS');

export const deleteTile = actionCreator('TILE_DELETE');

export const deleteTileSuccess = actionCreator('TILE_DELETE_SUCCESS');

export const sortTiles = actionCreator('TILE_SORT');

export const sortTilesSuccess = actionCreator('TILE_SORT_SUCCESS');
