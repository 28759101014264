//

import actionCreator from '../lib/actionCreator';

export const setLoading = actionCreator('SET_LOADING_STATE');

export const formError = actionCreator('FORM_ERROR');

export const clearFormErrors = actionCreator('FORM_ERROR_CLEAR');

// This is for compatibility reasons - it can be replaced by `APP_MESSAGE` action
// TODO remove and replace with showMessage
export const appError = actionCreator('APP_ERROR');

export const showMessage = actionCreator('APP_MESSAGE_ADD');

export const removeMessage = actionCreator('APP_MESSAGE_REMOVE');

export const openModal = actionCreator('MODAL_OPEN');

export const closeModal = actionCreator('MODAL_CLOSE');
