//

import { boardPermissionMapping } from '../constants';

export function getPermissionsForAll(visibility) {
  switch (visibility) {
    case 'public':
      return boardPermissionMapping['board:reader'];
    default:
      return boardPermissionMapping['board:none'];
  }
}
