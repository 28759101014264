import append from 'ramda/es/append';
import assocPath from 'ramda/es/assocPath';
import converge from 'ramda/es/converge';
import dissocPath from 'ramda/es/dissocPath';
import evolve from 'ramda/es/evolve';
import findIndex from 'ramda/es/findIndex';
import identity from 'ramda/es/identity';
import propEq from 'ramda/es/propEq';
import remove from 'ramda/es/remove';
import __ from 'ramda/es/__';

type ModalTypes = 'NEW_BOARD_MODAL' | 'NEW_TILE_MODAL' | 'PREVIEW_MODAL' | 'REAUTH_MODAL';

type UiState = {
  isLoading: Record<string, boolean>;
  modal: { type: ModalTypes; data?: any } | null;
  formError: Record<string, string>;
  formMessage: Record<string, string>;
  messages: AppMessage[];
};

const initialState = {
  isLoading: {},
  modal: null,
  formError: {},
  formMessage: {},
  messages: [],
};

function uiReducer(state: UiState = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING_STATE': {
      const { payload } = action;

      return assocPath(['isLoading', payload.stateKey], payload.value, state);
    }

    case 'MODAL_OPEN': {
      const { payload: modal } = action;

      return assocPath(['modal'], modal, state);
    }

    case 'MODAL_CLOSE': {
      return assocPath(['modal'], null, state);
    }

    case 'FORM_ERROR': {
      const { payload } = action;

      return assocPath(['formError', payload.form], payload.error, state);
    }

    case 'RESET_PASSWORD_SUCCESS': {
      return assocPath(['formMessage', 'resetPassword'], 'Email with instructions sent', state);
    }

    case 'FORM_ERROR_CLEAR': {
      const { payload } = action;

      return dissocPath(['formError', payload.formName], state);
    }

    case 'APP_ERROR': {
      const {
        payload: {
          error: { id, code },
        },
      } = action;

      return evolve({
        messages: append({ id, message: code, type: 'error' }),
      })(state);
    }
    case 'APP_MESSAGE_ADD': {
      const { payload } = action;

      return evolve({
        messages: append(payload.message),
      })(state);
    }

    case 'APP_MESSAGE_REMOVE': {
      const { payload } = action;

      return evolve({
        // @ts-ignore
        messages: converge(remove(__, 1, __), [findIndex(propEq('id', payload.id)), identity]),
      })(state);
    }

    case 'LOGOUT_SUCCESS': {
      return initialState;
    }

    default:
      return state;
  }
}

export default uiReducer;
