import evolve from 'ramda/es/evolve';
import compose from 'ramda/es/compose';
import merge from 'ramda/es/merge';
import ifElse from 'ramda/es/ifElse';
import has from 'ramda/es/has';
import assoc from 'ramda/es/assoc';
import identity from 'ramda/es/identity';
import assocPath from 'ramda/es/assocPath';
import __ from 'ramda/es/__';

export type SortTarget = {
  id: string;
  direction: 0 | 1; // 0 is left, 1 is right
};

export type TileState = {
  list: Record<string, Tile>;
  prevSortTarget: SortTarget;
};

const initialState: TileState = {
  list: {},
  prevSortTarget: {
    id: '',
    direction: 0,
  },
};

function tileReducer(state: TileState = initialState, action) {
  switch (action.type) {
    case 'FETCH_BOARD_TILES_SUCCESS': {
      const {
        payload: { boardId, tiles },
      } = action;

      return evolve({
        list: compose(
          evolve({
            [boardId]: merge(__, tiles),
          }),
          ifElse(has(boardId), identity, assoc(boardId, {})),
        ),
      })(state);
    }

    case 'TILE_SORT': {
      const {
        payload: { direction, targetId: id },
      } = action;
      return assocPath(['prevSortTarget'], { id, direction }, state);
    }

    case 'LOGOUT_SUCCESS': {
      return initialState;
    }

    default:
      return state;
  }
}

export default tileReducer;
