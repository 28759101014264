//

// TODO: wrote a working positioning logic
export function getTileSize() {
  return [1, 1];
}

export function getPerex(text: string) {
  // Get first 40 words
  return text
    .split(' ')
    .slice(0, 29)
    .join(' ');
}
