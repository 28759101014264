import ms from 'milliseconds';

export const APP_PREFIX = 'kb-';

// Used in hashids function, for HTML ids
export const HASH_SALT = 'Indie hacking for life';

export const DEFAULT_USER_TYPE = 'founder';
export const DEFAULT_TILE_TYPE = 'TextTile';
export const DEFAULT_BOARD_SECTION = 'default-section';
export const DEFAULT_BOARD_VISIBILITY = 'private';

// User's acount configuration - used to provide default acount settings
// can be override in the future by user specific settings fetched from server
export const USER_CONFIG = {
  validUploads: ['image/jpg', 'image/jpeg', 'image/png' /* , 'image/gif', 'image/svg+xml' */],
  maxUploadFileSize: 2097152, // 2MB = 1024 * 1024 * 2
  maxNameLength: 64,
};

// Image sets for different use cases. Used to fetch download urls from storage
// folders
export const IMAGE_SETS = {
  imageTile: [
    `${APP_PREFIX}thumb~200w`,
    `${APP_PREFIX}thumb~400w`,
    `${APP_PREFIX}preview~800w`,
    `${APP_PREFIX}preview~1200w`,
  ],
};

// time in milliseconds to hide global info message
export const ERROR_MSG_TIMEOUT = ms.seconds(7);

export const APP_URL = process.env.REACT_APP_APP_URL!;

export const ALGOLIA_INDEX = process.env.REACT_APP_ALGOLIA_INDEX!;
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID!;
export const ALGOLIA_PUBLIC_SEARCH_KEY = process.env.REACT_APP_ALGOLIA_PUBLIC_SEARCH_KEY!;
