/**
 * This has to be kept in sync with `qjub-extension/src/js/shared/constants/roles.ts`
 */
const RoleDefinitions = {
  none: {
    can: [],
    extend: [],
  },
  'board:reader': {
    can: ['board:read'],
    extend: [],
  },
  'board:editor': {
    can: ['tile:edit', 'tile:create', 'tile:delete', 'tile:sort'],
    extend: ['board:reader'],
  },
  'board:admin': {
    can: ['board:edit', 'board:delete', 'board:share', 'board:sections'],
    extend: ['board:editor'],
  },
  'board:owner': {
    can: [],
    extend: ['board:admin'],
  },
} as const;

export type RoleDefinitions = typeof RoleDefinitions;
export type Roles = keyof RoleDefinitions;
export type PermissionsActions = RoleDefinitions[Roles]['can'][number];

export default RoleDefinitions;
