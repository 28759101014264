/**
 * This has to be kept in sync with `qjub-extension/src/js/shared/constants/index.ts`
 */
import invertObj from 'ramda/es/invertObj';
import { Roles } from './roles';

// Mapping of permission for boards from abilities to numbers in order to use it in security rules
export const boardPermissionMapping = {
  'board:none': 0,
  // 'board:readMeta': 1, // read from boardMeta node to show listing of boards
  'board:reader': 10,
  'board:editor': 20, // create/edit/delete tiles in board,
  'board:admin': 40, // change board settings, delete, add board members, create/edit sections,
  'board:owner': 100, // so far no extra functionality but we can recognize board owner
} as const;

export const inverseBoardPermissionMapping = invertObj(boardPermissionMapping) as Record<
  RoleCodes,
  Roles
>;

type PermissionsMapping = typeof boardPermissionMapping;

export type RoleCodes = PermissionsMapping[keyof PermissionsMapping];

export const EMPTY_ARRAY = [];
