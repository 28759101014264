import { math } from 'polished';
import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import isNil from 'ramda/es/isNil';
import styled, { css } from 'styled-components/macro';
import { colorShade, getFontSize } from '../../lib/styles';

export const Button = styled.button<{
  variant?: 'ghost' | 'inverse';
  small?: boolean;
  fixedWidth?: boolean;
  color?: 'success' | 'error' | 'primary' | 'secondary';
  capitalize?: boolean;
}>`
  /*
  // @ts-ignore */
  ${({ theme, variant, color, small, fixedWidth = true, capitalize = false }) => css`
    ${cond([
      [
        equals('error'),
        () => css`
          --fg: ${theme.colors.white};
          --bg: ${theme.colors.error};
          &:hover,
          &:focus {
            --bg: ${colorShade(theme.colors.error, 2)};
          }

          &:active {
            --bg: ${colorShade(theme.colors.error, 3)};
          }
        `,
      ],
      [
        equals('success'),
        () => css`
          --fg: ${theme.colors.white};
          --bg: ${theme.colors.success};

          &:hover,
          &:focus {
            --bg: ${colorShade(theme.colors.success, 2)};
          }

          &:active {
            --bg: ${colorShade(theme.colors.success, 3)};
          }
        `,
      ],
      [
        equals('primary'),
        () => css`
          --fg: ${theme.colors.white};
          --bg: ${theme.colors.black};

          &:hover,
          &:focus {
            --bg: ${colorShade(theme.colors.black, 2)};
          }

          &:active {
            --bg: ${colorShade(theme.colors.black, 3)};
          }
        `,
      ],
      [
        equals('secondary'),
        () => css`
          --fg: ${theme.colors.white};
          --bg: ${theme.colors.gray5};

          &:hover,
          &:focus {
            --bg: ${colorShade(theme.colors.gray5, 2)};
          }

          &:active {
            --bg: ${colorShade(theme.colors.gray5, 3)};
          }
        `,
      ],
      [
        isNil,
        () => css`
          --fg: ${theme.colors.white};
          --bg: ${theme.colors.brand};

          &:hover,
          &:focus {
            --bg: ${colorShade(theme.colors.brand, 2)};
          }

          &:active {
            --bg: ${colorShade(theme.colors.brand, 3)};
          }
        `,
      ],
      // @ts-ignore
    ])(color)}

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-transform: ${capitalize ? 'capitalize' : 'inherit'};
    flex-shrink: 0;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    color: var(--fg);
    transition: color 125ms ease-out, background-color 125ms ease-out, border 125ms ease-out,box-shadow 125ms ease-out;
    font-weight: 500;
    text-decoration: none;

    ${
      small
        ? css`
            min-width: ${math(`${theme.sizes.gapFixedOne} * 3`)};
            padding: 0 ${theme.sizes.gapFixedHalf};
            ${getFontSize(theme.sizes.fontSmall)};
          `
        : css`
            height: ${theme.sizes.gapFixedTwo};
            min-width: ${math(`${theme.sizes.gapFixedOne} * 7`)};
            padding: ${math(`${theme.sizes.gapFixedOne} / 4`)} ${theme.sizes.gapFixedHalf};
            ${getFontSize(theme.sizes.fontBase)};
          `
    }

    ${!fixedWidth &&
      css`
        min-width: 0;
      `}

    ${variant === 'ghost' &&
      css`
        color: var(--bg);
        background-color: transparent;
        border: 0;
        text-decoration: dotted;
      `}
    ${variant === 'inverse' &&
      css`
        color: var(--bg);
        background-color: transparent;
        border-color: var(--bg);
        box-shadow: inset 0 0 0px 0px var(--bg);

        &:hover,
        &:focus {
          box-shadow: inset 0 0 0px 1px var(--bg);
        }
      `}
    ${!variant &&
      css`
        background-color: var(--bg);
        border-color: var(--bg);
      `}

    &:disabled {
      --fg: ${theme.colors.gray3};
      --bg: ${theme.colors.gray2};
      cursor: not-allowed;
    }

    @media ${theme.bp.tablet} {
      max-width: 18rem;
    }
  `}
`;

export const ButtonGroup = styled.div<{
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
}>`
  ${({ theme, justifyContent = 'flex-start' }) => css`
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    flex-wrap: wrap;
    margin: 0 ${math(`${theme.sizes.gapFixedOne} / -4`)};

    & > a,
    & > button {
      margin: 0 ${math(`${theme.sizes.gapFixedOne} / 4`)};
    }

    :empty {
      display: none;
    }
  `}
`;

export default Button;
