//

import actionCreator from '../lib/actionCreator';

export const resetPassword = actionCreator('RESET_PASSWORD');
export const resetPasswordSuccess = actionCreator('RESET_PASSWORD_SUCCESS');

export const resetPasswordFailure = actionCreator('RESET_PASSWORD_FAILURE');

export const logoutUser = actionCreator('LOGOUT');

export const logoutUserSuccess = actionCreator('LOGOUT_SUCCESS');

export const fetchUserSuccess = actionCreator('FETCH_USER_SUCCESS');

export const getSearchToken = actionCreator('GET_SEARCH_TOKEN');
export const getSearchTokenSuccess = actionCreator('GET_SEARCH_TOKEN_SUCCESS');
