export const firebaseConfig = {
  apiKey: 'AIzaSyBByir9_mOm7TnaEw9RZ2wKyIDS2kpCk64',
  authDomain: 'knowledge-board-5b896.firebaseapp.com',
  databaseURL: 'https://knowledge-board-5b896.firebaseio.com',
  projectId: 'knowledge-board-5b896',
  storageBucket: 'knowledge-board-5b896.appspot.com',
  messagingSenderId: '471883354075',
  appId: '1:471883354075:web:825f2c62406cb4570e61fe',
  measurementId: 'G-DEPK9XFPN8',
};
