/**
 * This has to be kept in sync with `qjub-extension/src/js/shared/services/firebase.js`
 */
/* eslint-disable prefer-promise-reject-errors */
import { getPermissionsForAll } from '../lib/boards';
import { enhanceError } from '../lib/common';
import { boardPermissionMapping } from '../constants';
import omit from 'ramda/es/omit';

/**
 * =============================
 * USER FIREBASE CALLS
 * =============================
 */
export const authChange = (firebase, action) =>
  firebase.auth().onAuthStateChanged(user => {
    action(user);

    if (!user) {
      // TODO: broken after react-router migration
      // browserHistory.push(`/login`)
    }
  });

export const logout = firebase => firebase.auth().signOut();

export const resetPassword = (firebase, email) => firebase.auth().sendPasswordResetEmail(email);

/**
 * =============================
 * BOARDS FIREBASE CALLS
 * =============================
 */
// TODO: refactor necessity of passing action prop - that should be handeled in epics
export const listenForUserBoards = (firebase, uid, action, errorAction) => {
  const boardsRef = firebase.database().ref(`userBoards/${uid}/`);

  boardsRef.on(
    'value',
    snapshot => {
      const boards = snapshot.val();
      action({ boards });
    },
    err => {
      const error = enhanceError(err, { context: 'FIR_user_boards_listener' });
      errorAction({ error });
    },
  );

  // return detaching listener function
  return () => boardsRef.off();
};

export const fetchUserBoards = (firebase, uid) => {
  const boardsRef = firebase.database().ref(`userBoards/${uid}/`);

  return boardsRef.once('value').then(snapshot => snapshot.val());
};

export const newBoard = (firebase, boardData, uid) => {
  const { visibility, boardId } = boardData;
  const boardMeta = omit(['sections'], boardData);

  const updates = {};
  const boardMembers = {
    [uid]: boardPermissionMapping['board:owner'],
    '*': getPermissionsForAll(visibility),
  };

  // We have to update each property individually in order to prevent deletetion of
  // omitted properties
  Object.entries(boardData).forEach(([key, value]) => {
    updates[`boards/${boardId}/${key}`] = value;
  });

  updates[`userBoards/${uid}/${boardId}`] = boardMeta;
  updates[`boardsMeta/${boardId}`] = boardMeta;
  updates[`boardMembers/${boardId}`] = boardMembers;

  return firebase
    .database()
    .ref()
    .update(updates);
};

/**
 * =============================
 * TILE FIREBASE CALLS
 * =============================
 */
// TODO: changing tile should probalby change tile only, Boards should be in separate api call
export const newTile = (firebase, tileData, boardId, sections, extraData = {}) => {
  const { id } = tileData;

  return firebase
    .database()
    .ref()
    .update({
      [`tilesByBoard/${boardId}/${id}`]: tileData,
      [`boardSections/${boardId}`]: sections,
      ...extraData,
    });
};

// TODO: add multi-file upload support
export function uploadToStorage(firebase, file, path) {
  const uploadTask = firebase
    .storage()
    .ref('uploads')
    .child(path)
    .put(file);

  // Callback for upload task
  // TODO: Handle error and progress - we shoud probably hint uploading process in snackbar
  // const next = snap => console.log('next', snap);
  // const error = error => {}; //eslint-disable-line
  // uploadTask.on('state_changed', next, error);

  return uploadTask;
}

export const deleteTile = (firebase, boardId, tileId, sections) =>
  firebase
    .database()
    .ref()
    .update({
      [`tilesByBoard/${boardId}/${tileId}`]: null,
      [`boardSections/${boardId}`]: sections,
    });

export const deleteBoard = (firebase, boardId, uid) =>
  firebase
    .database()
    .ref()
    .update({
      [`tilesByBoard/${boardId}`]: null,
      [`userBoards/${uid}/${boardId}`]: null,
      [`boardMembers/${boardId}`]: null,
      [`boards/${boardId}`]: null,
      [`boardsMeta/${boardId}`]: null,
      [`boardSections/${boardId}`]: null,
    });

export const updateBoardSections = (firebase, boardId, sections) =>
  firebase
    .database()
    .ref()
    .update({
      [`boardSections/${boardId}`]: sections,
    });
