//

import { combineEpics } from 'redux-observable';
import * as authEpics from './authEpics';
import * as boardEpics from './boardEpics';
import * as tileEpics from './tileEpics';
import * as uiEpics from './uiEpics';

// $FlowFixMe
const configureEpics = platformEpics => {
  const epics = Object.values({
    ...authEpics,
    ...boardEpics,
    ...tileEpics,
    ...uiEpics,
    ...platformEpics,
  });

  return combineEpics(...epics);
};

export default configureEpics;
